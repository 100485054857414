import { createEffect, onCleanup, onMount, Show } from "solid-js";
import { useNavigate } from "@solidjs/router";

import Illustration from "@repo/ui/branding/Illustration";
import Spinner from "@repo/ui/loaders/Spinner";

import { useUsers } from "~/state/features/users";

export default function ManualIdvPage() {
  const navigate = useNavigate();

  const [users, setUsers] = useUsers();

  createEffect(() => {
    const first = users.pendingManualIdvAttempts()?.[0];
    if (first) {
      navigate(`/manual-idv/${first.userId}`);
    }
  });

  return (
    <Show
      when={users.pendingManualIdvAttempts()?.length === 0}
      fallback={<Spinner.Common />}
    >
      {(() => {
        onMount(() => {
          let cancel = false;
          onCleanup(() => (cancel = true));

          (function poll() {
            if (cancel) return;
            setTimeout(() => {
              setUsers.fetchPendingManualIdvAttempts().then(poll);
            }, 1000);
          })();
        });

        return (
          <div class="h100% center-child">
            <div class="text-center">
              <Illustration name="check-decorated" size="large" />
              <div class="text:heading-4++ mb-2">No pending reviews</div>
              <div class="color:moderate">
                Manual IDV attempts by users will appear here as they come in.
              </div>
            </div>
          </div>
        );
      })()}
    </Show>
  );
}
