import c from "class-c";

import UObject from "@repo/utils/UObject";

import Rive from "../animation/Rive";

import styles from "./Illustration.module.scss";

declare namespace Illustration {
  type Props = {
    class?: string;
    name: Illustration.Name;
    size: Illustration.Size;
  };
  type Size = "small" | "medium" | "large" | "intrinsic";
  type Name = keyof typeof svgByName | keyof typeof riveByName;
}

function Illustration({ class: className, name, size }: D<Illustration.Props>) {
  const cls = c`${styles.illustration} ${styles[size]} ${className}`;

  return (
    <>
      {(() => {
        if (name in svgByName)
          return (
            <img class={cls} src={svgByName[name as keyof typeof svgByName]} />
          );

        if (name in riveByName)
          return (
            <Rive
              class={cls}
              src={riveByName[name as keyof typeof riveByName]}
            />
          );

        return (
          <div class={cls}>
            <span class="color:error"></span> {name}
          </div>
        );
      })()}
    </>
  );
}

export default Illustration;

const svgByName = UObject.trimKeys(
  // prettier-ignore
  import.meta.glob("./assets/illustrations/*", {
    eager: true,
    import: "default",
  }) as {
/* GENERATED */ "./assets/illustrations/$150-money.svg": typeof import("./assets/illustrations/$150-money.svg")["default"],
/* GENERATED */ "./assets/illustrations/200 direct deposit.svg": typeof import("./assets/illustrations/200 direct deposit.svg")["default"],
/* GENERATED */ "./assets/illustrations/3B-Disputes-Premium.svg": typeof import("./assets/illustrations/3B-Disputes-Premium.svg")["default"],
/* GENERATED */ "./assets/illustrations/Alert-action.svg": typeof import("./assets/illustrations/Alert-action.svg")["default"],
/* GENERATED */ "./assets/illustrations/Alert.svg": typeof import("./assets/illustrations/Alert.svg")["default"],
/* GENERATED */ "./assets/illustrations/Bank-2.svg": typeof import("./assets/illustrations/Bank-2.svg")["default"],
/* GENERATED */ "./assets/illustrations/Bar graph.svg": typeof import("./assets/illustrations/Bar graph.svg")["default"],
/* GENERATED */ "./assets/illustrations/Bar-chart.svg": typeof import("./assets/illustrations/Bar-chart.svg")["default"],
/* GENERATED */ "./assets/illustrations/BillBuster-simplified.svg": typeof import("./assets/illustrations/BillBuster-simplified.svg")["default"],
/* GENERATED */ "./assets/illustrations/BillBuster.svg": typeof import("./assets/illustrations/BillBuster.svg")["default"],
/* GENERATED */ "./assets/illustrations/Boost.svg": typeof import("./assets/illustrations/Boost.svg")["default"],
/* GENERATED */ "./assets/illustrations/Calendar-with-bg.svg": typeof import("./assets/illustrations/Calendar-with-bg.svg")["default"],
/* GENERATED */ "./assets/illustrations/Calendar-with-tasks.svg": typeof import("./assets/illustrations/Calendar-with-tasks.svg")["default"],
/* GENERATED */ "./assets/illustrations/Car-loan.svg": typeof import("./assets/illustrations/Car-loan.svg")["default"],
/* GENERATED */ "./assets/illustrations/Card-display.svg": typeof import("./assets/illustrations/Card-display.svg")["default"],
/* GENERATED */ "./assets/illustrations/Cash-Card-Flip.svg": typeof import("./assets/illustrations/Cash-Card-Flip.svg")["default"],
/* GENERATED */ "./assets/illustrations/Cash-Card-Front.svg": typeof import("./assets/illustrations/Cash-Card-Front.svg")["default"],
/* GENERATED */ "./assets/illustrations/Checkmark.svg": typeof import("./assets/illustrations/Checkmark.svg")["default"],
/* GENERATED */ "./assets/illustrations/Coming soon.svg": typeof import("./assets/illustrations/Coming soon.svg")["default"],
/* GENERATED */ "./assets/illustrations/Complete.svg": typeof import("./assets/illustrations/Complete.svg")["default"],
/* GENERATED */ "./assets/illustrations/Congrats.svg": typeof import("./assets/illustrations/Congrats.svg")["default"],
/* GENERATED */ "./assets/illustrations/Connect-card.svg": typeof import("./assets/illustrations/Connect-card.svg")["default"],
/* GENERATED */ "./assets/illustrations/Credit Account - Bureaus.svg": typeof import("./assets/illustrations/Credit Account - Bureaus.svg")["default"],
/* GENERATED */ "./assets/illustrations/Credit Usage Celebration.svg": typeof import("./assets/illustrations/Credit Usage Celebration.svg")["default"],
/* GENERATED */ "./assets/illustrations/Credit-increase.svg": typeof import("./assets/illustrations/Credit-increase.svg")["default"],
/* GENERATED */ "./assets/illustrations/Data-graph.svg": typeof import("./assets/illustrations/Data-graph.svg")["default"],
/* GENERATED */ "./assets/illustrations/Debt_scissors-2.svg": typeof import("./assets/illustrations/Debt_scissors-2.svg")["default"],
/* GENERATED */ "./assets/illustrations/Debt_scissors.svg": typeof import("./assets/illustrations/Debt_scissors.svg")["default"],
/* GENERATED */ "./assets/illustrations/Dispute letter.svg": typeof import("./assets/illustrations/Dispute letter.svg")["default"],
/* GENERATED */ "./assets/illustrations/Dispute result.svg": typeof import("./assets/illustrations/Dispute result.svg")["default"],
/* GENERATED */ "./assets/illustrations/Dispute_letter.svg": typeof import("./assets/illustrations/Dispute_letter.svg")["default"],
/* GENERATED */ "./assets/illustrations/EQ receive results.svg": typeof import("./assets/illustrations/EQ receive results.svg")["default"],
/* GENERATED */ "./assets/illustrations/Gift with bg.svg": typeof import("./assets/illustrations/Gift with bg.svg")["default"],
/* GENERATED */ "./assets/illustrations/Gift-with-bow.svg": typeof import("./assets/illustrations/Gift-with-bow.svg")["default"],
/* GENERATED */ "./assets/illustrations/Gift.svg": typeof import("./assets/illustrations/Gift.svg")["default"],
/* GENERATED */ "./assets/illustrations/Home-loan-with-chart.svg": typeof import("./assets/illustrations/Home-loan-with-chart.svg")["default"],
/* GENERATED */ "./assets/illustrations/Home-loan.svg": typeof import("./assets/illustrations/Home-loan.svg")["default"],
/* GENERATED */ "./assets/illustrations/How CA Works [Basic].svg": typeof import("./assets/illustrations/How CA Works [Basic].svg")["default"],
/* GENERATED */ "./assets/illustrations/How CA Works [Premium].svg": typeof import("./assets/illustrations/How CA Works [Premium].svg")["default"],
/* GENERATED */ "./assets/illustrations/Journey-with-pin.svg": typeof import("./assets/illustrations/Journey-with-pin.svg")["default"],
/* GENERATED */ "./assets/illustrations/Letter.svg": typeof import("./assets/illustrations/Letter.svg")["default"],
/* GENERATED */ "./assets/illustrations/Lightbox-detail.svg": typeof import("./assets/illustrations/Lightbox-detail.svg")["default"],
/* GENERATED */ "./assets/illustrations/Lightbox.svg": typeof import("./assets/illustrations/Lightbox.svg")["default"],
/* GENERATED */ "./assets/illustrations/Loading.svg": typeof import("./assets/illustrations/Loading.svg")["default"],
/* GENERATED */ "./assets/illustrations/Mastercard.svg": typeof import("./assets/illustrations/Mastercard.svg")["default"],
/* GENERATED */ "./assets/illustrations/Meter-with-bars.svg": typeof import("./assets/illustrations/Meter-with-bars.svg")["default"],
/* GENERATED */ "./assets/illustrations/Meter-with-graphs.svg": typeof import("./assets/illustrations/Meter-with-graphs.svg")["default"],
/* GENERATED */ "./assets/illustrations/Meter-with-rocket-bg.svg": typeof import("./assets/illustrations/Meter-with-rocket-bg.svg")["default"],
/* GENERATED */ "./assets/illustrations/Meter-with-rocket-details.svg": typeof import("./assets/illustrations/Meter-with-rocket-details.svg")["default"],
/* GENERATED */ "./assets/illustrations/Meter-with-rocket.svg": typeof import("./assets/illustrations/Meter-with-rocket.svg")["default"],
/* GENERATED */ "./assets/illustrations/Mountain-with-pin.svg": typeof import("./assets/illustrations/Mountain-with-pin.svg")["default"],
/* GENERATED */ "./assets/illustrations/Mountain.svg": typeof import("./assets/illustrations/Mountain.svg")["default"],
/* GENERATED */ "./assets/illustrations/New offer.svg": typeof import("./assets/illustrations/New offer.svg")["default"],
/* GENERATED */ "./assets/illustrations/One million.svg": typeof import("./assets/illustrations/One million.svg")["default"],
/* GENERATED */ "./assets/illustrations/Payment on time.svg": typeof import("./assets/illustrations/Payment on time.svg")["default"],
/* GENERATED */ "./assets/illustrations/Payment.svg": typeof import("./assets/illustrations/Payment.svg")["default"],
/* GENERATED */ "./assets/illustrations/Piggy bank.svg": typeof import("./assets/illustrations/Piggy bank.svg")["default"],
/* GENERATED */ "./assets/illustrations/Piggy bank_2.svg": typeof import("./assets/illustrations/Piggy bank_2.svg")["default"],
/* GENERATED */ "./assets/illustrations/Privacy-Protection.svg": typeof import("./assets/illustrations/Privacy-Protection.svg")["default"],
/* GENERATED */ "./assets/illustrations/Problems-check.svg": typeof import("./assets/illustrations/Problems-check.svg")["default"],
/* GENERATED */ "./assets/illustrations/Problems.svg": typeof import("./assets/illustrations/Problems.svg")["default"],
/* GENERATED */ "./assets/illustrations/Removed.svg": typeof import("./assets/illustrations/Removed.svg")["default"],
/* GENERATED */ "./assets/illustrations/Rent reporting.svg": typeof import("./assets/illustrations/Rent reporting.svg")["default"],
/* GENERATED */ "./assets/illustrations/Rocket-with-bg.svg": typeof import("./assets/illustrations/Rocket-with-bg.svg")["default"],
/* GENERATED */ "./assets/illustrations/Score-bump-with-bg.svg": typeof import("./assets/illustrations/Score-bump-with-bg.svg")["default"],
/* GENERATED */ "./assets/illustrations/Search-web.svg": typeof import("./assets/illustrations/Search-web.svg")["default"],
/* GENERATED */ "./assets/illustrations/Search-with-bg.svg": typeof import("./assets/illustrations/Search-with-bg.svg")["default"],
/* GENERATED */ "./assets/illustrations/See you later.svg": typeof import("./assets/illustrations/See you later.svg")["default"],
/* GENERATED */ "./assets/illustrations/Social.svg": typeof import("./assets/illustrations/Social.svg")["default"],
/* GENERATED */ "./assets/illustrations/Subscription Management.svg": typeof import("./assets/illustrations/Subscription Management.svg")["default"],
/* GENERATED */ "./assets/illustrations/TU receive results.svg": typeof import("./assets/illustrations/TU receive results.svg")["default"],
/* GENERATED */ "./assets/illustrations/Thumb-up.svg": typeof import("./assets/illustrations/Thumb-up.svg")["default"],
/* GENERATED */ "./assets/illustrations/Timer.svg": typeof import("./assets/illustrations/Timer.svg")["default"],
/* GENERATED */ "./assets/illustrations/Trophy.svg": typeof import("./assets/illustrations/Trophy.svg")["default"],
/* GENERATED */ "./assets/illustrations/Update report.svg": typeof import("./assets/illustrations/Update report.svg")["default"],
/* GENERATED */ "./assets/illustrations/Warning.svg": typeof import("./assets/illustrations/Warning.svg")["default"],
/* GENERATED */ "./assets/illustrations/announce.svg": typeof import("./assets/illustrations/announce.svg")["default"],
/* GENERATED */ "./assets/illustrations/apartment-building-magnifying-glass.svg": typeof import("./assets/illustrations/apartment-building-magnifying-glass.svg")["default"],
/* GENERATED */ "./assets/illustrations/apartment-building-phone-credit-check-disabled.svg": typeof import("./assets/illustrations/apartment-building-phone-credit-check-disabled.svg")["default"],
/* GENERATED */ "./assets/illustrations/apartment-building-phone-credit-check.svg": typeof import("./assets/illustrations/apartment-building-phone-credit-check.svg")["default"],
/* GENERATED */ "./assets/illustrations/app-update.svg": typeof import("./assets/illustrations/app-update.svg")["default"],
/* GENERATED */ "./assets/illustrations/badge-caution.svg": typeof import("./assets/illustrations/badge-caution.svg")["default"],
/* GENERATED */ "./assets/illustrations/badge-check.svg": typeof import("./assets/illustrations/badge-check.svg")["default"],
/* GENERATED */ "./assets/illustrations/bank-3.svg": typeof import("./assets/illustrations/bank-3.svg")["default"],
/* GENERATED */ "./assets/illustrations/bank-money-stack-3-bureaus.svg": typeof import("./assets/illustrations/bank-money-stack-3-bureaus.svg")["default"],
/* GENERATED */ "./assets/illustrations/bank-money-stack.svg": typeof import("./assets/illustrations/bank-money-stack.svg")["default"],
/* GENERATED */ "./assets/illustrations/bar-chart-rocket.svg": typeof import("./assets/illustrations/bar-chart-rocket.svg")["default"],
/* GENERATED */ "./assets/illustrations/basic-limit.svg": typeof import("./assets/illustrations/basic-limit.svg")["default"],
/* GENERATED */ "./assets/illustrations/bird-mail.svg": typeof import("./assets/illustrations/bird-mail.svg")["default"],
/* GENERATED */ "./assets/illustrations/box-closed.svg": typeof import("./assets/illustrations/box-closed.svg")["default"],
/* GENERATED */ "./assets/illustrations/box-open-money-shield.svg": typeof import("./assets/illustrations/box-open-money-shield.svg")["default"],
/* GENERATED */ "./assets/illustrations/box-open-money.svg": typeof import("./assets/illustrations/box-open-money.svg")["default"],
/* GENERATED */ "./assets/illustrations/box-simplified.svg": typeof import("./assets/illustrations/box-simplified.svg")["default"],
/* GENERATED */ "./assets/illustrations/box-turn on notifications.svg": typeof import("./assets/illustrations/box-turn on notifications.svg")["default"],
/* GENERATED */ "./assets/illustrations/calendar-check.svg": typeof import("./assets/illustrations/calendar-check.svg")["default"],
/* GENERATED */ "./assets/illustrations/calendar-direct-deposit-money-stack.svg": typeof import("./assets/illustrations/calendar-direct-deposit-money-stack.svg")["default"],
/* GENERATED */ "./assets/illustrations/calendar-transfer-money.svg": typeof import("./assets/illustrations/calendar-transfer-money.svg")["default"],
/* GENERATED */ "./assets/illustrations/card-arrow.svg": typeof import("./assets/illustrations/card-arrow.svg")["default"],
/* GENERATED */ "./assets/illustrations/card-exclamation-point.svg": typeof import("./assets/illustrations/card-exclamation-point.svg")["default"],
/* GENERATED */ "./assets/illustrations/card-recurring.svg": typeof import("./assets/illustrations/card-recurring.svg")["default"],
/* GENERATED */ "./assets/illustrations/card-scissors-fees.svg": typeof import("./assets/illustrations/card-scissors-fees.svg")["default"],
/* GENERATED */ "./assets/illustrations/card-scissors.svg": typeof import("./assets/illustrations/card-scissors.svg")["default"],
/* GENERATED */ "./assets/illustrations/card-sissors-coins-objects.svg": typeof import("./assets/illustrations/card-sissors-coins-objects.svg")["default"],
/* GENERATED */ "./assets/illustrations/card-turn on notifications.svg": typeof import("./assets/illustrations/card-turn on notifications.svg")["default"],
/* GENERATED */ "./assets/illustrations/card_calendar_check-arrow.svg": typeof import("./assets/illustrations/card_calendar_check-arrow.svg")["default"],
/* GENERATED */ "./assets/illustrations/cards-multiple.svg": typeof import("./assets/illustrations/cards-multiple.svg")["default"],
/* GENERATED */ "./assets/illustrations/cash-back.svg": typeof import("./assets/illustrations/cash-back.svg")["default"],
/* GENERATED */ "./assets/illustrations/cash-card-check-dollar-sign.svg": typeof import("./assets/illustrations/cash-card-check-dollar-sign.svg")["default"],
/* GENERATED */ "./assets/illustrations/cash-card-movement.svg": typeof import("./assets/illustrations/cash-card-movement.svg")["default"],
/* GENERATED */ "./assets/illustrations/cash-card-rewards.svg": typeof import("./assets/illustrations/cash-card-rewards.svg")["default"],
/* GENERATED */ "./assets/illustrations/check-decorated-parachute.svg": typeof import("./assets/illustrations/check-decorated-parachute.svg")["default"],
/* GENERATED */ "./assets/illustrations/check-decorated.svg": typeof import("./assets/illustrations/check-decorated.svg")["default"],
/* GENERATED */ "./assets/illustrations/check-dollar-sign-clock.svg": typeof import("./assets/illustrations/check-dollar-sign-clock.svg")["default"],
/* GENERATED */ "./assets/illustrations/coin-dollar-plant.svg": typeof import("./assets/illustrations/coin-dollar-plant.svg")["default"],
/* GENERATED */ "./assets/illustrations/coin-leaves.svg": typeof import("./assets/illustrations/coin-leaves.svg")["default"],
/* GENERATED */ "./assets/illustrations/coin-plant.svg": typeof import("./assets/illustrations/coin-plant.svg")["default"],
/* GENERATED */ "./assets/illustrations/coin-stack.svg": typeof import("./assets/illustrations/coin-stack.svg")["default"],
/* GENERATED */ "./assets/illustrations/credit-report-data.svg": typeof import("./assets/illustrations/credit-report-data.svg")["default"],
/* GENERATED */ "./assets/illustrations/credit-report-document-leaves.svg": typeof import("./assets/illustrations/credit-report-document-leaves.svg")["default"],
/* GENERATED */ "./assets/illustrations/credit-report-document.svg": typeof import("./assets/illustrations/credit-report-document.svg")["default"],
/* GENERATED */ "./assets/illustrations/credit-score-coin-leaves-objects.svg": typeof import("./assets/illustrations/credit-score-coin-leaves-objects.svg")["default"],
/* GENERATED */ "./assets/illustrations/credit-score-leaves-1.svg": typeof import("./assets/illustrations/credit-score-leaves-1.svg")["default"],
/* GENERATED */ "./assets/illustrations/credit-score-leaves-2-green-bg.svg": typeof import("./assets/illustrations/credit-score-leaves-2-green-bg.svg")["default"],
/* GENERATED */ "./assets/illustrations/credit-score-leaves-2.svg": typeof import("./assets/illustrations/credit-score-leaves-2.svg")["default"],
/* GENERATED */ "./assets/illustrations/credit-score-leaves-3.svg": typeof import("./assets/illustrations/credit-score-leaves-3.svg")["default"],
/* GENERATED */ "./assets/illustrations/customer-support.svg": typeof import("./assets/illustrations/customer-support.svg")["default"],
/* GENERATED */ "./assets/illustrations/cut-receipt.svg": typeof import("./assets/illustrations/cut-receipt.svg")["default"],
/* GENERATED */ "./assets/illustrations/debt-negotiation-1.svg": typeof import("./assets/illustrations/debt-negotiation-1.svg")["default"],
/* GENERATED */ "./assets/illustrations/dispute-send.svg": typeof import("./assets/illustrations/dispute-send.svg")["default"],
/* GENERATED */ "./assets/illustrations/disputes-bulk-send.svg": typeof import("./assets/illustrations/disputes-bulk-send.svg")["default"],
/* GENERATED */ "./assets/illustrations/document-coin-objects.svg": typeof import("./assets/illustrations/document-coin-objects.svg")["default"],
/* GENERATED */ "./assets/illustrations/dollar-sign-recurring.svg": typeof import("./assets/illustrations/dollar-sign-recurring.svg")["default"],
/* GENERATED */ "./assets/illustrations/gift-parachute.svg": typeof import("./assets/illustrations/gift-parachute.svg")["default"],
/* GENERATED */ "./assets/illustrations/hourglass-clock.svg": typeof import("./assets/illustrations/hourglass-clock.svg")["default"],
/* GENERATED */ "./assets/illustrations/hourglass-coin.svg": typeof import("./assets/illustrations/hourglass-coin.svg")["default"],
/* GENERATED */ "./assets/illustrations/isometric-spam-email.svg": typeof import("./assets/illustrations/isometric-spam-email.svg")["default"],
/* GENERATED */ "./assets/illustrations/kikoff-coming-soon.svg": typeof import("./assets/illustrations/kikoff-coming-soon.svg")["default"],
/* GENERATED */ "./assets/illustrations/mail-equifax-parachute.svg": typeof import("./assets/illustrations/mail-equifax-parachute.svg")["default"],
/* GENERATED */ "./assets/illustrations/mail-notification.svg": typeof import("./assets/illustrations/mail-notification.svg")["default"],
/* GENERATED */ "./assets/illustrations/medical-bill-1.svg": typeof import("./assets/illustrations/medical-bill-1.svg")["default"],
/* GENERATED */ "./assets/illustrations/medical-bill-2.svg": typeof import("./assets/illustrations/medical-bill-2.svg")["default"],
/* GENERATED */ "./assets/illustrations/monthly-bill-1.svg": typeof import("./assets/illustrations/monthly-bill-1.svg")["default"],
/* GENERATED */ "./assets/illustrations/monthly-bill-2.svg": typeof import("./assets/illustrations/monthly-bill-2.svg")["default"],
/* GENERATED */ "./assets/illustrations/parachute-dollar-sign.svg": typeof import("./assets/illustrations/parachute-dollar-sign.svg")["default"],
/* GENERATED */ "./assets/illustrations/pie-chart-point.svg": typeof import("./assets/illustrations/pie-chart-point.svg")["default"],
/* GENERATED */ "./assets/illustrations/piggy-bank-green.svg": typeof import("./assets/illustrations/piggy-bank-green.svg")["default"],
/* GENERATED */ "./assets/illustrations/plus-58.svg": typeof import("./assets/illustrations/plus-58.svg")["default"],
/* GENERATED */ "./assets/illustrations/premium-limit.svg": typeof import("./assets/illustrations/premium-limit.svg")["default"],
/* GENERATED */ "./assets/illustrations/rent-apartment-door-keys.svg": typeof import("./assets/illustrations/rent-apartment-door-keys.svg")["default"],
/* GENERATED */ "./assets/illustrations/rocket-bg-illos.svg": typeof import("./assets/illustrations/rocket-bg-illos.svg")["default"],
/* GENERATED */ "./assets/illustrations/rocket-check-dollar-sign.svg": typeof import("./assets/illustrations/rocket-check-dollar-sign.svg")["default"],
/* GENERATED */ "./assets/illustrations/rocket-packages.svg": typeof import("./assets/illustrations/rocket-packages.svg")["default"],
/* GENERATED */ "./assets/illustrations/shield-id-document.svg": typeof import("./assets/illustrations/shield-id-document.svg")["default"],
/* GENERATED */ "./assets/illustrations/target-3-bureaus.svg": typeof import("./assets/illustrations/target-3-bureaus.svg")["default"],
/* GENERATED */ "./assets/illustrations/taxi-leadership.svg": typeof import("./assets/illustrations/taxi-leadership.svg")["default"]
},
  "./assets/illustrations/",
  ".svg",
);

const riveByName = UObject.trimKeys(
  // prettier-ignore
  import.meta.glob("./assets/illustrations/rive/*", {
    eager: true,
    import: "default",
  }) as {
/* GENERATED */ "./assets/illustrations/rive/3_bureaus.riv": typeof import("./assets/illustrations/rive/3_bureaus.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/Score-simulator.riv": typeof import("./assets/illustrations/rive/Score-simulator.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/billbuster.riv": typeof import("./assets/illustrations/rive/billbuster.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/bottom.riv": typeof import("./assets/illustrations/rive/bottom.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/complete 2.riv": typeof import("./assets/illustrations/rive/complete 2.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/complete_1.riv": typeof import("./assets/illustrations/rive/complete_1.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/credit-insights-rocket.riv": typeof import("./assets/illustrations/rive/credit-insights-rocket.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/debt-negotiation.riv": typeof import("./assets/illustrations/rive/debt-negotiation.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/dispute_2.riv": typeof import("./assets/illustrations/rive/dispute_2.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/dispute_5.riv": typeof import("./assets/illustrations/rive/dispute_5.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/dispute_7.riv": typeof import("./assets/illustrations/rive/dispute_7.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/dispute_loading.riv": typeof import("./assets/illustrations/rive/dispute_loading.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/education.riv": typeof import("./assets/illustrations/rive/education.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/education_2.riv": typeof import("./assets/illustrations/rive/education_2.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/insights-bar-anim.riv": typeof import("./assets/illustrations/rive/insights-bar-anim.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/instant dispute.riv": typeof import("./assets/illustrations/rive/instant dispute.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/lightbox.riv": typeof import("./assets/illustrations/rive/lightbox.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/loading dot.riv": typeof import("./assets/illustrations/rive/loading dot.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/loading.riv": typeof import("./assets/illustrations/rive/loading.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/problem-accounts.riv": typeof import("./assets/illustrations/rive/problem-accounts.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/results 2.riv": typeof import("./assets/illustrations/rive/results 2.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/results.riv": typeof import("./assets/illustrations/rive/results.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/rocket 1.riv": typeof import("./assets/illustrations/rive/rocket 1.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/rocket 2.riv": typeof import("./assets/illustrations/rive/rocket 2.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/rocket 3.riv": typeof import("./assets/illustrations/rive/rocket 3.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/rocket dispute.riv": typeof import("./assets/illustrations/rive/rocket dispute.riv")["default"],
/* GENERATED */ "./assets/illustrations/rive/subscription_1.riv": typeof import("./assets/illustrations/rive/subscription_1.riv")["default"]
},
  "./assets/illustrations/rive/",
  ".riv",
);
